import React from "react";
import Layout from "../components/ui/Layout";
import Column from "../components/ui/Column";
import LinkedText from "../components/ui/LinkedText";
import Heading from "../components/ui/Heading";
import * as styles from "./documents.module.scss";

function DocumentsPage() {
  return (
    <Layout>
      <Column>
        <Heading>Documents</Heading>
        <ul
          className={styles["list"]}
          style={{
            padding: "5rem 0 0 1rem",
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
          }}
        >
          <li>
            <LinkedText external={false} to="questions-for-richard-corcoran">
              NCFLF Questions for Richard Corcoran
            </LinkedText>{" "}
            March 24, 2024
          </li>
        </ul>
      </Column>
    </Layout>
  );
}

export default DocumentsPage;
